<script setup lang="ts">
import {useDevice} from "~/composables/useDevice";

const {site_url, site_name, site_persian_name} = useRuntimeConfig().public

const {isDesktop} = useDevice()
</script>

<template>
  <header class="w-full h-[var(--header-height)] bg-white border-b border-gray-200 shadow-sm">
    <div class="main-width flex justify-between items-center p-4 mx-auto">
      <nuxt-link
          to="/"
          :aria-label="site_persian_name"
          class="flex items-center gap-2"
      >
        <u-avatar
            src="logo.png"
            :alt="site_name"
            size="md"
          />
        <span class="text-md text-gray-900 tracking-tight font-semibold">{{site_persian_name}}</span>
      </nuxt-link>

      <div v-if="isDesktop" class="flex items-center justify-center">
        <lazy-basic-nav-top-bar-component />
      </div>

      <div class="flex justify-end">
        <u-button
            color="white"
            variant="soft"
            :ui="{base: 'text-gray-800 hover:text-primary transition-colors duration-400'}"
            label="ورود / ثبت نام"
        />
      </div>
    </div>
  </header>
</template>
