<script setup lang="ts">
import {instagramIcon, xIcon} from "~/utils/icon-utils";

const currentYear = new Date().getFullYear();
const isIran = useState('isIran');
const ip = useState('ip');
</script>

<template>
    <footer class="bg-gray-900 text-white py-8">
        <div class="container mx-auto px-6">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
                <div>
                    <h3 class="text-lg font-semibold mb-3">درباره ما</h3>
                    <p class="text-sm text-gray-300">
                        موتور جستجوی پیشرفته برای پروازها، هتل‌ها و خدمات گردشگری از بهترین آژانس‌های ایران.
                    </p>
                </div>

                <div>
                    <h3 class="text-lg font-semibold mb-3">لینک‌های مفید</h3>
                    <ul class="space-y-2">
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">درباره ما</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">تماس با ما</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">شرایط و قوانین</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">سوالات متداول</u-link>
                        </li>
                    </ul>
                </div>

                <div>
                    <h3 class="text-lg font-semibold mb-3">خدمات ما</h3>
                    <ul class="space-y-2">
                        <li>
                            <u-link to="/flight" class="text-gray-300 hover:text-yellow-400">پرواز داخلی</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">رزرو هتل</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">تورهای مسافرتی</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">بلیط قطار</u-link>
                        </li>
                        <li>
                            <u-link to="#" class="text-gray-300 hover:text-yellow-400">بلیط اتوبوس</u-link>
                        </li>
                    </ul>
                </div>

                <div>
                    <h3 class="text-lg font-semibold mb-3">ارتباط با ما</h3>
                    <p class="text-sm text-gray-300">📞 ۰۲۱-۱۲۳۴۵۶۷۸</p>
                    <p class="text-sm text-gray-300">✉ support[at]example.com</p>
                    <div class="flex gap-4 mt-3">
                        <u-button :icon="instagramIcon()" disabled target="_blank" color="gray" variant="ghost"
                                  size="lg"/>
                        <u-button :icon="xIcon()" disabled target="_blank" color="gray" variant="ghost" size="lg"/>
                    </div>
                    <div
                        v-if="isIran"
                        class="w-20"
                    >
                        <nuxt-link
                            referrerpolicy='origin'
                            format="webp"
                            target='_blank'
                            to="https://trustseal.enamad.ir/?id=588949&Code=0j5t7JeQRzLy3C2rDMJIK31hVSNV0PQw"
                            rel="nofollow"
                            class="flex items-center justify-center border border-gray-200 p-1.5 rounded-[5px]"
                        >
                            <nuxt-img
                                class="w-12"
                                referrerpolicy='origin'
                                format="webp"
                                src='https://trustseal.enamad.ir/logo.aspx?id=588949&Code=0j5t7JeQRzLy3C2rDMJIK31hVSNV0PQw'
                                alt=''
                                style='cursor:pointer'
                                Code='0j5t7JeQRzLy3C2rDMJIK31hVSNV0PQw'
                            />
                        </nuxt-link>
                    </div>
                </div>
            </div>

            <div class="mt-8 text-center border-t border-gray-700 pt-4">
                <p class="text-gray-400 text-sm">© {{ currentYear }} تمامی حقوق محفوظ است.</p>
            </div>
        </div>
    </footer>
</template>
