<script setup lang="ts">
defineProps<{
    noHeader?: boolean,
    noFooter?: boolean,
}>()

const deviceState = useState('deviceState', () => useDevice());
const isMobileOrTablet = computed(() => deviceState.value.isMobileOrTablet);
const isDesktop = computed(() => deviceState.value.isDesktop);
</script>

<template>
    <basic-header-component v-if="!noHeader"/>
    <slot name="screenBox"/>
    <main class="main-width w-full flex flex-col mx-auto lg:py-5 lg:pb-10">
        <slot/>
    </main>
    <basic-footer-component v-if="isDesktop" />
</template>
